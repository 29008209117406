import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../shared/img/logo.png';
import { accoApiUrl } from '../../services/helpers';
import axios from 'axios';
import { Col, Container, Row } from 'reactstrap';

const PwReset = (props: any) => {
    const {} = props;

    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(-1);
    const [payError, setPayError] = useState('');
    const [formData, setFormData] = useState({
        email: ''
    });

    const submitForm = async () => {
        setSubmitting(true);
        try {
            const res: any = await axios.post(accoApiUrl() + '/auth/pwforget', formData);
            if (res.data.success) {
                setSuccess(1);
            }
            setSubmitting(false);

        } catch (err) {
            setSubmitting(false);
            setPayError('There was an error while signing you up for the PwReset.');
        }
    }

    return (
        <div className="cmt-login auth-form">
            <div className="cmt-login-inner">
                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <div className="cmt-login__title">
                                <img src={logo} alt="Accomods Logo" className="logo"/>
                            </div>
                            {success !== 1 ?
                                <div className='pf-login-panel'>
                                    <h1>Reset Your Password</h1>
                                    <p>Can't sign in to your account? Use this form to reset your password.</p>
                                    <p>Enter your email address, and if it exists in our system you will receive an
                                        email with a special link where you can then change your password.<br/>&nbsp;
                                    </p>

                                    <div className={`form-group`}>
                                        <label>Email Address</label>
                                        <div className="auth-form-group">
                                            <div className="ico-left"><i className="fas fa-envelope"></i></div>
                                            <input
                                                name="userOrEmail"
                                                value={formData.email}
                                                type="text"
                                                onChange={(e: any) => setFormData({
                                                    ...formData,
                                                    email: e.target.value
                                                })}
                                                className="form-control input-ico-left"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {payError !== '' &&
                                      <div className="alert alert-danger"
                                           style={{ fontSize: '16px', marginTop: '24px' }}>
                                        <b>{payError}</b>
                                      </div>
                                    }

                                    <div>
                                        <div style={{ marginTop: '26px',float:'right',fontStyle:'italic'}}>
                                            <Link to={'/'}>Never mind, back to login...</Link>
                                        </div>
                                        <button className="btn btn-success"
                                                style={{ marginTop: '16px', paddingLeft: '60px', paddingRight: '60px' }}
                                                onClick={() => submitForm()}
                                                disabled={submitting}>&nbsp; &nbsp; Submit &nbsp; &nbsp;</button>
                                    </div>

                                    <br/><br/>
                                </div>
                                :
                                <div className='pf-login-panel'>
                                    <div className="alert alert-success">
                                        <b style={{ fontSize: '20px' }}>Email Sent!</b>
                                    </div>
                                    <br/>
                                    <p style={{ fontSize: '16px' }}>Please check your inbox for an email from Accomods.
                                        This email will contain a link that will redirect you to a special form allowing
                                        you to reset your password.</p>
                                    <p style={{ fontSize: '16px' }}>Having problems? Please contact Accomods support at <a
                                            href="mailto:admin@actiondriveneducation.com">admin@actiondriveneducation.com</a>
                                    </p>
                                    <br/><br/>
                                    <Link to={"/"} className="btn btn-primary"
                                          style={{ paddingLeft: '40px', paddingRight: '40px' }}>Back
                                        to Home</Link>
                                </div>
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(PwReset);

