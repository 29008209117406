import React, { useState } from 'react';
import logo from '../../shared/img/logo.png';
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Link, Navigate } from 'react-router-dom';

import { setAlert } from '../../redux/actions/alert';
import Alert from '../Layout/Alert';

import { connect } from 'react-redux';
import { login } from '../../redux/actions/auth';


const Login = ( props: any ) => {
    const [formData, setFormData] = useState( { userOrEmail:'', password:''});
    const [showPassword, toggleShowPassword] = useState(false);

    const { userOrEmail, password } = formData;
    const { setAlert, login, auth, profile } = props;

    const onChange = (e:any) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e:any) => {
        e.preventDefault();
        await login({ userOrEmail, password });
    };

    const hasToken = auth && auth.access_token;
    const profileLoaded = profile && profile.profile && profile.profile.id;

    return (
        // hasToken ? <Navigate to="/dashboard" /> :
        <div className="cmt-login">
            <div className="cmt-login-inner">
                <Container>
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <div className="cmt-login__title">
                                <img src={logo} alt="Accomods Logo" className="logo"/>
                            </div>
                            <Card>
                                <CardHeader>Sign In</CardHeader>
                                <CardBody>
                                    <Alert alertKey="signinError" />
                                    <form className="auth-form" onSubmit={e => onSubmit(e)}>
                                        <div className={`form-group`}>
                                            <label>Email Address</label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="fas fa-envelope"></i></div>
                                                <input
                                                    name="userOrEmail"
                                                    value={userOrEmail}
                                                    type="text"
                                                    onChange={e => onChange(e)}
                                                    className="form-control input-ico-left"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className={`form-group`}>
                                            <label>
                                                <div style={{float:'right',fontStyle:'italic'}}>
                                                    <Link to={'/pwreset'}>Forget Your Password?</Link>
                                                </div>
                                                Password
                                            </label>
                                            <div className="auth-form-group">
                                                <div className="ico-left"><i className="fas fa-key"></i></div>
                                                <input
                                                    name="password"
                                                    value={password}
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={e => onChange(e)}
                                                    minLength={6}
                                                    className="form-control input-ico-left input-ico-right"
                                                    required
                                                />
                                                <div className="ico-right"
                                                     onClick={() => toggleShowPassword(!showPassword)}>
                                                    <i className={`fas ` + (showPassword ? 'fa-lock-open-alt' : 'fa-lock-alt')}></i>
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="flex-row-between">
                                            <input type={`submit`} value={` Sign In `} className={`btn btn-primary`} />
                                            <Link className="btn btn-success" to="/register">Need an Account? Sign Up Here</Link>
                                        </div>

                                    </form>
                                </CardBody>
                            </Card>

                            <div className="what-is-accomods">
                                <div>
                                    <a href="https://actiondriveneducation.com" target="_blank">
                                        <img src="https://actiondriveneducation.com/wp-content/uploads/2020/08/cropped-ADE-Final-Master-Logo.png" />
                                    </a>
                                </div>
                                <div><h4>What is Accomods?</h4><p>Accomods is an interactive, user-friendly application
                                    that guides the development of appropriate, Specially Designed Instruction for
                                    students with special needs. By aligning student's strengths and learning needs to
                                    hundreds of accommodations and modifications, Accomods efficiently engages IEP teams
                                    in critical thinking to develop and implement appropriate supports that meet
                                    learners' needs.</p><p><a href="https://actiondriveneducation.com" target="_blank">Learn
                                    more at ActionDrivenEducation.com</a></p></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};


const mapStateToProps = (state: any) => ({
    auth: state.auth,
    profile: state.profile
});

export default connect(mapStateToProps, { setAlert, login })(Login);



